import './index.css';

const Panorama = ({ isOpen, src, handleClose }) => {
  return (
    <div class={`panorama ${src ? 'panorama--open_mod' : ''}`}>
      <button className="panorama__close" onClick={handleClose}>
        <svg
          viewBox="0 0 24 24"
          height="24"
          width="24"
          aria-hidden="true"
          fill="currentcolor"
          tabindex="-1"
          focusable="false"
          role="img"
        >
          <path d="M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6L19 6.4z"></path>
        </svg>
      </button>
      <iframe
        className="panorama__iframe"
        frameborder="0"
        id="vr-preload"
        marginheight="0"
        marginwidth="0"
        scrolling="yes"
        src={src}
        title="panorama iframe"
      ></iframe>
    </div>
  );
};

export default Panorama;
