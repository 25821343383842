import { DEFAULT_FEATURES_RENDER_LIMIT } from 'jets-seatmap-react-lib';

const TooltipGlobal = ({ data }) => {
  const { label, classType, features, measurements, price, rowName, additionalProps } = data;

  const finalListOfFeatures = [...(features || []), ...(additionalProps || [])].slice(0, DEFAULT_FEATURES_RENDER_LIMIT);

  return (
    <div className={`jets-tooltip--body`}>
      <div className="jets-tooltip--content">
        <div className="jets-tooltip--header" style={{ color: 'rgb(79, 111, 143)', direction: 'ltr' }}>
          <div className="jets-tooltip--header-title">
            {rowName || classType} {label}
          </div>
          <div className="jets-tooltip--header-price">{price === 'USD 0' ? 'Free' : price}</div>
        </div>

        <div className="jets-tooltip--features">
          <ul>
            {finalListOfFeatures.map(({ uniqId, title, icon, value, cssClass }) => (
              <li className={`jets-tooltip--feature ${cssClass || ''}`} key={uniqId}>
                {icon ? (
                  <span
                    className={`svg_span ${cssClass ? cssClass + '-icon' : ''}`}
                    dangerouslySetInnerHTML={{
                      __html: icon,
                    }}
                  ></span>
                ) : (
                  <span>{title}</span>
                )}
                <div
                  className={`${cssClass ? cssClass + '-label' : ''}`}
                  style={cssClass ? {} : { color: 'rgb(79, 111, 143);' }}
                >
                  {value}
                </div>
              </li>
            ))}
            {finalListOfFeatures.length % 2 == 1 && <li className="jets-tooltip--feature">&nbsp;</li>}
          </ul>
        </div>

        <div className="jets-tooltip--measurements">
          {measurements.map(({ uniqId, title, icon, value }) => (
            <div
              style={{ borderColor: 'rgb(79, 111, 143)', background: 'rgb(255, 255, 255)' }}
              className="jets-tooltip--measurement"
              key={uniqId}
            >
              {icon ? (
                <span
                  className="svg_span"
                  style={{ fill: 'rgb(79, 111, 143)' }}
                  dangerouslySetInnerHTML={{
                    __html: icon,
                  }}
                ></span>
              ) : (
                <span></span>
              )}
              <div className="jets-tooltip--measurement-box">
                <div className="jets-tooltip--measurement-value">{title}</div>
                <div className="jets-tooltip--measurement-value">{value}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TooltipGlobal;
