import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, FreeMode, Navigation } from 'swiper/modules';
import './index.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import 'swiper/css/navigation';
import { useEffect, useRef, useState } from 'react';

const GalleryPopupSlide = ({ panorama, image, text, isThumb }) => {
  return (
    <div className="gallery__slider_item">
      {panorama && !isThumb && (
        <div class="gallery__slider_picture">
          <iframe
            className="gallery__slider_iframe"
            frameborder="0"
            id="vr-preload"
            marginheight="0"
            marginwidth="0"
            scrolling="yes"
            src={panorama}
            title="panorama iframe"
            allow="fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      )}
      {!panorama && !isThumb && (
        <div className="gallery__slider_picture">
          <img src={image} alt={text} className="gallery__slider_image" />
        </div>
      )}
      {isThumb && (
        <div className="gallery__slider_picture">
          <img src={image} alt={text} className="gallery__slider_image" />
        </div>
      )}
      {text && <div className="gallery__slider_text">{text}</div>}
      {panorama && isThumb && (
        <div className="gallery__slider_360_pic">
          <img src="360.svg" alt="360 icon" className="gallery__slider_360_img" />
        </div>
      )}
    </div>
  );
};

const GalleryPopup = ({ isOpen, showPanorama, hidePopup, slides, slideIndex }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const sliderRef = useRef(null);
  const thumbsRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current && slideIndex !== undefined) {
      sliderRef.current.swiper.slideTo(slideIndex);
      thumbsRef.current.swiper.slideTo(slideIndex);
    }
  }, [slideIndex]);

  return (
    <div className={`gallery ${isOpen ? 'gallery--open_mod' : ''}`}>
      <button className="gallery__overlay" onClick={hidePopup} />
      <div className="gallery__content">
        <button class="gallery__close" onClick={hidePopup}>
          <svg
            viewBox="0 0 24 24"
            height="24"
            width="24"
            aria-hidden="true"
            fill="currentcolor"
            tabindex="-1"
            focusable="false"
            role="img"
          >
            <path d="M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6L19 6.4z"></path>
          </svg>
        </button>
        <div className="gallery__title">Aircraft gallery</div>

        <div className="gallery__slider">
          <Swiper
            ref={sliderRef}
            allowTouchMove={false}
            loop={true}
            modules={[FreeMode, Navigation, Thumbs]}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          >
            {slides?.length &&
              slides.map(item => {
                return (
                  <SwiperSlide>
                    <GalleryPopupSlide text={item.text} image={item.image} panorama={item.panorama} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>

        <div class="gallery__thumbs">
          <Swiper
            ref={thumbsRef}
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            modules={[FreeMode, Navigation, Thumbs]}
            navigation={true}
          >
            {slides?.length &&
              slides.map(item => {
                return (
                  <SwiperSlide>
                    <GalleryPopupSlide
                      isThumb
                      image={item.image}
                      panorama={item.panorama}
                      showPanorama={showPanorama}
                      panoramaSrc={item.panorama}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default GalleryPopup;
