import './index.css';

const Gallery = ({ gallery, hoverGalleryCategory, showPopup }) => {
  return (
    <div class="seatmaps_gallery">
      {gallery?.slice(0, 2).map((item, index) => {
        return (
          <div class="seatmaps_gallery__item" key={index}>
            <button class="seatmaps_gallery__btn" onClick={() => showPopup(index)}></button>
            <img className="seatmaps_gallery__img" src={item.image} alt="" loading="lazy" />
          </div>
        );
      })}
    </div>
  );
};

export default Gallery;
